<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10 col-xl-8 text-justify">
        <p>Acantilado y Anagrama se unen para dar continuidad a la publicación de las obras del gran escritor belga. El proyecto conjunto tiene como objetivo revitalizar y ofrecer a un público amplio el que André Gide consideraba "el novelista más grande y más auténtico", adorado por artistas tan importantes como Federico Fellini, William Faulkner, Charles Chaplin, Josep Pla, Walter Benjamin o Gabriel García Márquez.</p>
        <p>Las traducciones están al cuidado de Caridad Martínez y Núria Petit y el diseño es del estudio Duró con las  ilustraciones de Maria Picassó. Tanto en el cuidado del texto como de la imagen intentamos mostrar la plasticidad de un estilo inigualable, que escapa a todas las clasificaciones.</p>
        <div class="row">
          <div class="col-md mt-5 mb-md-5 text-md-end order-0">
            <img loading="lazy" class="img-fluid" width="139" height="17" src="@/assets/logo-anagrama.svg" alt="Anagrama">
          </div>
          <div class="col-auto mt-5 mb-md-5 px-md-3 d-none d-md-block order-md-1">
            <img loading="lazy" class="img-fluid" width="13" height="13" src="@/assets/et.svg" alt="&amp;">
          </div>
          <div class="col-md mt-5 mb-md-5 text-md-start order-2 order-md-2">
            <img loading="lazy" class="img-fluid" width="186" height="17" src="@/assets/logo-acantilado.svg" alt="Acantilado">
          </div>
          <div class="w-100 d-none d-md-block order-md-3"></div>
          <div class="col-md-6 my-3 order-1 order-md-4">
            <div class="pe-md-4">
              <p>Esa cualidad de los clásicos para hablar con efectividad, con verdad, a los que no son sus contemporáneos es realmente impresionante de George Simenon. En sus novelas, los personajes se agitan, están vivos desde la primera página, desde las primeras cuatro líneas maestras podemos casi olerlos, se tridimensionalizan, se entreveran en nuestra vida, primero, y después se hacen un lugar insidiosamente perdurable en nuestra memoria. Intentar resolver cómo lo hace, novela tras novela, es un estímulo sobrado para la lectura atenta. Comprobar cómo sigue conmoviendo y desconcertando después de 80 años, de ese modo tan formidable y adictivo.</p>
              <p>Es una ocasión feliz unir esfuerzos con el Acantilado y publicar a Simenon, compartir con los nuevos lectores una obra que ya forma parte de la mitología literaria, de la novela negra y de la novela universal.</p>
              <p class="lh-sm">Silvia Sesé<br>
              Anagrama</p>
            </div>
          </div>
          <div class="col-md-6 my-3 order-3 order-md-5">
            <div class="ps-md-4">
              <p>La literatura de Simenon es deslumbrante por su poder narrativo, por esa capacidad suya de penetrar la realidad, sus engaños y sus trampas, por su finísima observación de los personajes y sus circunstancias, y todo ello con un estilo sencillo, austero en ocasiones, que cautiva al lector desde las primeras páginas. Presentar de nuevo sus libros, en esta colaboración con la editorial Anagrama, es una enorme alegría y la oportunidad de propiciar un descubrimiento a nuevos lectores que seguro será feliz.</p>
              <p class="lh-sm">Sandra Ollo<br>
              Acantilado</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { useMeta } from 'vue-meta'
export default {
  name: 'Proyecto',
  setup () {
    useMeta({
      title: 'El proyecto',
      og: {
        title: 'El proyecto | Georges Simenon',
        url: window.location.href
      },
      twitter: {
        title: 'El proyecto | Georges Simenon'
      }
    })
    onMounted(() => {
      document.body.style.backgroundColor = '#ef5755'
      document.body.style.backgroundImage = 'url("' + require('@/assets/background/proyecto.gif') + '")'
    })
  }
}
</script>
